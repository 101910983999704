import React from 'react';

const About = () => (
  <section id="about">
    <h3>About Us</h3>
    <p>Since our establishment in 2016, American World Resources has become a trusted name in global energy solutions. Our expertise in federal contracting and commercial fuel delivery has positioned us as a key player in the international energy market. With a team of seasoned professionals, we offer unparalleled consulting services tailored to meet the complex energy needs of governments and multinational organizations.</p>
    <p>At AWR, we understand the critical nature of energy security and its impact on global operations. Our discreet and efficient approach ensures that our clients receive the highest level of service, maintaining operational integrity at all times.</p>
  </section>
);

export default About;