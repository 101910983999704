import React from 'react';

const Team = () => (
  <section id="team">
    <h3>Our Leadership</h3>
    <p>American World Resources is led by a team of distinguished professionals with extensive experience in global energy markets, international logistics, and strategic operations. Our leadership brings together expertise from various sectors, including government contracting, multinational energy corporations, and international security.</p>
    <p>This unique blend of skills and experience positions AWR to understand and address the complex energy needs of our clients, ensuring that we deliver solutions that are not just effective, but also aligned with the highest standards of discretion and professionalism.</p>
  </section>
);

export default Team;