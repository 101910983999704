import React from 'react';

const Contact = () => (
  <section id="contact">
    <h3>Contact Us</h3>
    <p>For inquiries regarding our services or to discuss how American World Resources can support your organization's energy needs, please reach out to our dedicated client relations team. We assure you of our utmost discretion and prompt attention to your requirements.</p>
    <button onClick={() => window.location = 'mailto:christian@a-wr.com'}>Request Information</button>
  </section>
);

export default Contact;