import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => (
  <section id="home">
    <h3>Global Energy Solutions</h3>
    <p>American World Resources (AWR) is your trusted partner in strategic fuel procurement and logistics management. We specialize in delivering reliable energy solutions to governments, international organizations, and large-scale operations worldwide.</p>
    <ul>
      <li>Secure and efficient fuel supply chain</li>
      <li>Expert logistics in challenging environments</li>
      <li>Tailored energy consulting services</li>
    </ul>
    <div className="cta-container">
      <Link to="/contact" className="cta-button">Contact Us</Link>
      <Link to="/services" className="secondary-button">Our Services</Link>
    </div>
  </section>
);

export default Home;