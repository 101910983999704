import React from 'react';

const Services = () => (
  <section id="services">
    <h3>Our Services</h3>
    <ul>
      <li><strong>Strategic Fuel Procurement:</strong> Leveraging our global network to secure competitive pricing on a diverse range of fuel products, ensuring uninterrupted supply for large-scale operations.</li>
      <li><strong>Advanced Logistics Management:</strong> Coordinating complex transportation and delivery systems to guarantee timely and secure fuel distribution across challenging terrains and sensitive locations.</li>
      <li><strong>Specialized Energy Consulting:</strong> Providing expert guidance on fuel management strategies, supply chain optimization, and risk mitigation to enhance operational efficiency and energy security.</li>
    </ul>
  </section>
);

export default Services;