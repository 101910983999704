import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import About from './components/About';
import Mission from './components/Mission';
import Services from './components/Services';
import Team from './components/Team';
import Contact from './components/Contact';

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="App-nav">
          <Link to="/">
            <img src={process.env.PUBLIC_URL + '/favicon.ico'} alt="AWR - American World Resources Logo" className="App-logo" />
          </Link>
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/mission">Mission</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/team">Team</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>

        <header className="App-header">
          <h1>American World Resources</h1>
          <h2>Your Reliable Fuel and Logistics Solutions Partner</h2>
        </header>

        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/services" element={<Services />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <footer className="App-footer">
          <p>© {new Date().getFullYear()} American World Resources. All Rights Reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
