import React from 'react';

const Mission = () => (
  <section id="mission">
    <h3>Our Mission</h3>
    <p>American World Resources is committed to delivering strategic fuel solutions and comprehensive logistical support to our esteemed clients worldwide. We leverage our extensive network and industry expertise to ensure seamless fuel procurement and delivery, even in the most challenging environments.</p>
    <p>Our mission is to enhance global energy security by providing reliable, efficient, and discreet services that empower our clients to focus on their core operations. We strive to be the cornerstone of energy logistics for governments and international organizations, fostering stability and growth on a global scale.</p>
  </section>
);

export default Mission;